<template>
  <v-card flat min-height="100vh">
    <v-card-text>
      <v-card flat width="842" class="mx-auto">
        <v-row
          dense
          align="center"
          justify="center"
          v-if="step === 'payment_method'"
        >
          <v-col
            v-if="invoice && invoice.status === 'pending'"
            :cols="mdAndUp ? 8 : 12"
          >
            <v-sheet class="d-flex pa-5 flex-column">
              <span class="fw-700 fs-14 primary--text">
                Select from the following secure payment methods:
              </span>
              <v-row
                :dense="!mdAndUp"
                align="start"
                justify="start"
                class="flex-wrap"
                :class="{ 'flex-column': !mdAndUp }"
              >
                <label
                  class="col-3 d-flex align-center justify-start cursor-pointer"
                >
                  <v-checkbox
                    value="stripe"
                    disabled
                    off-icon="mdi-checkbox-blank-circle-outline"
                    on-icon="mdi-checkbox-marked-circle"
                    v-model="payment_method"
                  ></v-checkbox>
                  <div class="d-flex flex-column primary--text">
                    <strong class="fw-900 fs-20"> Stripe </strong>
                    <span class="fs-10 fw-700">Coming soon</span>
                  </div>
                </label>
                <label
                  class="col-3 d-flex align-center justify-start cursor-pointer"
                >
                  <v-checkbox
                    value="paypal"
                    disabled
                    off-icon="mdi-checkbox-blank-circle-outline"
                    on-icon="mdi-checkbox-marked-circle"
                    v-model="payment_method"
                  ></v-checkbox>
                  <div class="d-flex flex-column primary--text">
                    <strong class="fw-900 fs-20"> PayPal </strong>
                    <span class="fs-10 fw-700">Coming soon</span>
                  </div>
                </label>
                <label
                  class="col-3 d-flex align-center justify-start cursor-pointer"
                >
                  <v-checkbox
                    value="card"
                    off-icon="mdi-checkbox-blank-circle-outline"
                    on-icon="mdi-checkbox-marked-circle"
                    v-model="payment_method"
                  ></v-checkbox>
                  <div
                    class="
                      d-flex
                      flex-column
                      align-start
                      justify-start
                      primary--text
                    "
                  >
                    <strong class="fw-900 fs-20 mb-1"> Card </strong>
                    <div class="d-flex align-start justify-start">
                      <v-avatar width="10" height="15" tile>
                        <v-img
                          contain
                          :src="
                            require('@/assets/appIcons/mastercard.svg').default
                          "
                        ></v-img>
                      </v-avatar>
                      <v-avatar width="10" height="15" tile>
                        <v-img
                          contain
                          :src="require('@/assets/appIcons/visa.svg').default"
                        ></v-img>
                      </v-avatar>
                    </div>
                  </div>
                </label>
              </v-row>
            </v-sheet>
          </v-col>
          <v-col
            v-if="invoice && invoice.status === 'pending'"
            :cols="mdAndUp ? 4 : 12"
            class="d-flex align-center justify-center"
          >
            <v-btn
              min-width="200"
              @click="step = payment_method"
              depressed
              :disabled="!payment_method"
              class="text-none px-5"
              color="primary"
            >
              Pay Now
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            v-if="invoice && invoice.status === 'paid'"
            class="d-flex my-5 align-center justify-center flex-column"
          >
            <h1 class="success--text fw-900">Invoice is already paid!</h1>
            <v-btn
              @click="$router.go(-1)"
              class="px-5 text-none mt-4"
              small
              color="primary"
              depressed
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center" v-if="step === 'card'">
          <v-btn
            min-width="200"
            :disabled="!payment_method"
            @click="step = 'payment_method'"
            depressed
            class="text-none px-5 my-5"
            color="primary"
          >
            Select Other Payment Method
          </v-btn>
        </v-row>
      </v-card>
    </v-card-text>
    <v-card-text v-if="step === 'payment_method'">
      <v-card
        ref="foo"
        flat
        v-if="invoice && invoice.template" 
        width="842"
        :min-height="mdAndUp ? '100vh' : '100%'"
        class="mx-auto"
      >
        <component
          v-if="invoice.template.component.includes('InvoiceDefault')"
          :is="invoice.template.component"
          :create-mode="false"
          :overflow="!mdAndUp"
          :value="invoice.colors"
        ></component>
      </v-card>
    </v-card-text>
    <v-card-text v-else-if="step === 'card'">
      <InvoiceCardPaymentMethod
        v-if="invoice"
        :id="invoice.id"
        @payment-success="handlePaymentSucceed"
      >
        <template v-slot:default>
          <v-card
            flat 
            v-if="invoice.template" 
            class="mx-auto"
            min-width="550"
          >
            <component
              v-if="invoice.template.component.includes('InvoiceDefault')"
              :is="invoice.template.component"
              :create-mode="false"
              :value="invoice.colors"
            ></component>
          </v-card>
        </template>
      </InvoiceCardPaymentMethod>
    </v-card-text>
    <v-card-text v-else-if="step === 'payment_succeed'">
      <Empty
        icon="mdi-check"
        min-height="500"
        headline="Invoice payment successfully submitted"
      >
        <template v-slot:extra>
          <v-btn
            color="primary"
            depressed
            class="text-none px-5"
            @click="$router.push({ name: 'invoice' })"
          >
            Go back to Invoices
          </v-btn>
        </template>
      </Empty>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import InvoiceDefault1 from "../../InvoiceTemplates/templates/InvoiceDefault1.vue";
import InvoiceDefault2 from "../../InvoiceTemplates/templates/InvoiceDefault2.vue";
import InvoiceDefault3 from "../../InvoiceTemplates/templates/InvoiceDefault3.vue";
import InvoiceDefault4 from "../../InvoiceTemplates/templates/InvoiceDefault4.vue";
import InvoiceDefault5 from "../../InvoiceTemplates/templates/InvoiceDefault5.vue";
import InvoiceCardPaymentMethod from "../components/InvoiceCardPaymentMethod.vue";

export default {
  components: {
    InvoiceDefault1,
    InvoiceDefault2,
    InvoiceDefault3,
    InvoiceDefault4,
    InvoiceDefault5,
    InvoiceCardPaymentMethod,
  },
  data: () => ({
    loading: false,
    step: "payment_method",
    payment_method: "card",
  }),
  watch: {
    // "$route.params.id": "fetchInvoice",
  },
  computed: {
    ...mapGetters("invoice", ["invoice"]),
  },
  created() {
    this.fetchInvoice({ id: this.$route.params.id });
    this.set_create_mode(false);
  },
  methods: {
    ...mapMutations("invoice", ["set_create_mode"]),
    ...mapActions("invoice", ["fetchInvoice"]),
    handlePaymentSucceed(status) {
      if (status) {
        this.step = "payment_succeed";
      }
    },
  },
};
</script>
<style>
</style>